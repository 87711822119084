import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Wysiwyg from './Wysiwyg'
import ContactInfo from './ContactInfoContainer'
import Title from './Title'
import Separator from './Separator'
import SteppedImageMask from './SteppedImageMask'

// styles
// ==========================================================================================
const Heading = styled(Title)`
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 24px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 36px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: ${({ theme }) => theme.colors.gray10};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-bottom: 70%;
  }
`
const Iframe = styled.iframe`
  position: absolute;
  left: 5%;
  height: 100%;
  width: 90%;
  border: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    left: 0;
    width: 100%;
  }
`

// component
// ==========================================================================================
const Map = ({ highlightTitle, contactBody, mapUrl }) => {
  return (
    <div>
      <Row>
        <Col xs={7} xsOffset={1}>
          <Heading tag="h2" content={highlightTitle} />
        </Col>
      </Row>
      <Row middle="xs">
        <Col xs={10} lg={6}>
          <ImageWrapper>
            <SteppedImageMask />
            <Iframe frameborder="0" src={`${mapUrl}&zoom=13`} allowfullscreen />
          </ImageWrapper>
        </Col>
        <Col xs={8} xsOffset={1} lg={2} lgOffset={1}>
          <Wysiwyg content={contactBody} />
          <Separator small />
          <ContactInfo isOnWhite hasIcons />
        </Col>
      </Row>
    </div>
  )
}

export default Map
