import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Map from '../components/Map'
import Separator from '../components/Separator'

export const query = graphql`
  query ContactQuery {
    prismic {
      data: contact(uid: "contact", lang: "en-gb") {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image
        heroLabel: hero_label
        heroTitle: hero_title
        heroBody: hero_body
        heroImage: hero_image
        heroImageSharp: hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        intro
        contactBody: contact_body
        contactEmail: contact_email
        contactPhone: contact_phone
        contactAddress: contact_address
        mapUrl: map_url
      }
    }
  }
`

const AboutPage = ({
  data: {
    prismic: { data },
  },
}) => {
  return (
    <Layout {...data}>
      <Hero {...data} />
      <Separator />
      <Content>
        <Map {...data} />
      </Content>
    </Layout>
  )
}

// AboutPage.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default AboutPage
